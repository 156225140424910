import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../../../components/layout"
import Seo from "../../../components/seo"
import Image from "../../../components/image"
import { FaChevronRight } from "react-icons/fa"
import Helmet from "react-helmet"

const brandPage = ({ data, location, pageContext: { brand_id } }) => {
	const brand = data.brandsMilkYaml
	const itemStr = 'ミルク'
	const unitStr = '100ml'
	const category = 'milk'
	const categoryIndex = '/milk/milk_powder_can_big_all_all'
	let description = `${brand.description} ${itemStr}${unitStr}あたりの最安値！`
	let preTitle = ``

	// パンくずリストの構造化データ
	const jsonLd = {
		"@context": "https://schema.org",
		"@type": "BreadcrumbList",
		"itemListElement": [{
			"@type": "ListItem",
			"position": 1,
			"name": "トップ",
			"item": "https://hula-la.com/"
		},{
			"@type": "ListItem",
			"position": 2,
			"name": itemStr,
			"item": "https://hula-la.com" + categoryIndex
		},{
			"@type": "ListItem",
			"position": 3,
			"name": brand.description,
			"item": "https://hula-la.com"+location.pathname
		}]
	};

	// allContentfulItemsを100ml当たりの価格が安い順に並び替える(Graphqlのソートだと100円以上の場合に並び替えが正しくできないため数値のソートを自前で行う)
	data.allContentfulItems.edges.sort(function(a,b){
		if((!a.node.itemList.length) && (!b.node.itemList.length)){
			return 0
		}
		// itemList無ければ後ろへ並べる
		else if((!a.node.itemList.length) && (b.node.itemList.length)){
			return 1
		}
		else if((a.node.itemList.length) && (!b.node.itemList.length)){
			return -1
		}
		return a.node.itemList[0].per - b.node.itemList[0].per
	})	

	return (
  <Layout>
			<>
				<Helmet>
     			<script type="application/ld+json">{JSON.stringify(jsonLd)}</script>
   			</Helmet>
				{data.allTypesMilkYaml.edges.map(edge => {
					const type = edge.node
					const item = data.allContentfulItems.edges.find(n => {
						return n.node.typeId === type.cid
					})
					if(!item) return <></>
					description += `${type.description}タイプ `
					const sizeStr = '' 
					const itemStr = 'ミルク'
					return (
						<>
						<div className="content" style={{marginTop: `0.5rem`}}>
							<h3 className="title is-6" style={{marginBottom: `0.5rem`, marginLeft: `0.5rem`}}>{brand.description} {type.description} {unitStr}あたりの最安値</h3>
							<div className="columns is-multiline is-gapless">
								{data.allSizesMilkYaml.edges.map(edge => {
			            const size = edge.node
									const item = data.allContentfulItems.edges.find(n => {
										return n.node.typeId === type.cid && n.node.sizeId === size.cid && n.node.itemList.length !== 0
									})
									if(!item) return <></>
									const filename = `${brand.cid}_${type.cid}_${size.cid}_${item.node.subbrandId}.jpg`
									if(!item.node.itemList.length) return <></>
									description += `${size.description}${item.node.itemList[0].per.toFixed(1)}円 `
									preTitle += preTitle === `` ? `${item.node.itemList[0].per.toFixed(1)}円/${unitStr}` : ``
									return (
										<div className="column is-half">
											<Link to={`/${category}/${brand.cid}_${type.cid}_${size.cid}.html`}>
												<div className="card">
													<div className="card-content" style={{padding: `0.8rem`}}>
														<div className="media">
															<div className="media-left">
																<Image filename={filename} alt={`${brand.description}${type.description}タイプ${size.description}${sizeStr}の${itemStr}パッケージ画像`}　/>
															</div>
															<div className="media-content">
																<div className="content">
																		<p>
																			<span style={{fontSize:`0.7rem`}}><strong>{size.description}{sizeStr}</strong></span>
																			<br />
																			<span style={{color: `#ff0000`}} className="is-size-4">&nbsp;{item.node.itemList[0].per.toFixed(1)}<span style={{fontSize:`0.7rem`}}> 円/{unitStr}</span> ～</span>
																			<br />
																			<span className="tag" style={{fontSize:`0.6rem`}}>{type.description}</span>
																		</p>
																	</div>
																</div>
															<div className="media-right">
																<span className="icon" style={{height: `2.5em`}}>
																	<FaChevronRight />
																</span>
															</div>
														</div>
													</div>
												</div>
											</Link>
										</div>
									)
								})}
							</div>
						</div>
						<br></br>
						</>
					)
				})}
			</>
		<Seo title={`${brand.description}`} description={description} canonical={location.pathname} />
		<nav className="breadcrumb is-small" aria-label="breadcrumbs" style={{marginBottom: `0.5rem`, marginLeft: `0.5rem`}}>
			<ul>
				<li><Link to="/">トップ</Link></li>
				<li><Link to={`${categoryIndex}`}>{itemStr}</Link></li>
				<li className="is-active"><Link to="" aria-current="page">{brand.description}</Link></li>
			</ul>
		</nav>		
  </Layout>
	)
}

export const query = graphql`
	query($brand_id: String!) {
		brandsMilkYaml(cid: { eq: $brand_id}) {
			cid
			description
		}
		allTypesMilkYaml {
			edges {
				node {
					cid
					description
				}
			}
		}
		allSizesMilkYaml {
			edges {
				node {
					cid
					description
				}
			}
		}
		allContentfulItems(filter: {brandId: { eq: $brand_id}}) {
			edges {
				node {
					brandId
					typeId
					sizeId
					subbrandId
					itemList {
						num
						shopId
						shop
						href
						per
						price
						sendPrice
						souryou
						txt
						point
					}
				}
			}
		}
		allSubbrandsMilkYaml {
			edges {
				node {
					brand_id
					subbrand_id
					description
					types {
						type_id 
						sizes {
							size_id
							num	
							unit
							use
							pack_unit
						}
					}
				}
			}
		}
	}
`

export default brandPage
